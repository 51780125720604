dmx.Attribute('bind', 'mounted', function(node, attr) {
    var name = attr.argument;
    var toggle = dmx.reToggleAttribute.test(name);

    this.$addBinding(attr.value, function(value) {
        if (toggle) {
            node[name] = !!value;
        } else {
            if (name === 'style' && typeof value === 'object') {
                for (var prop in value) {
                    node.style[prop] = value[prop];
                }
                return;
            }

            node.setAttribute(name, value);
        }
    });
});
